<template>
	<Head title="Enter Password"></Head>
	<GuestPortal :show-nav="false">
		<section id="login-page">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-xl-4 col-md-6 col-12">
						<form @submit.prevent="submit" class="bg-white shadow py-lg-10 px-lg-12 py-8 px-6">
							<h1 class="h2 text-primary text-center lh-1 mb-4">Welcome!</h1>
							<p class="text-center">Please enter the passcode provided to you in your "save the date" message in the box below to access the site.</p>

							<div class="form-group d-flex flex-column mb-6">
								<InputLabel for="password" value="Passcode:" class="h5 mb-2"/>
								<TextInput
										type="password"
										id="password"
										name="password"
										v-model="form.password"
										placeholder-value="Enter Passcode ..."
										required
										class="mb-2"
										pattern="[0-9]*"
										inputmode="numeric"
								/>
								<InputError :message="form.errors.password" remove-margin/>
							</div>
							<div class="form-group d-flex justify-content-center">
								<button class="btn-outline-primary text-primary fw-bold" type="submit">Login</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	</GuestPortal>
</template>

<script setup>
	import GuestPortal from "@layouts/GuestPortal.vue";
	import { Head, useForm } from "@inertiajs/vue3";
	import InputLabel from "@js/Components/InputLabel.vue";
	import TextInput from "@js/Components/TextInput.vue";
	import InputError from "@js/Components/InputError.vue";

	const form = useForm({
		password: '',
		remember: false
	});

	const submit = () => {
		form.post(route('attempt-login'), {
			onFinish: () => form.reset('password'),
		});
	}
</script>