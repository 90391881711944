<template>
	<footer id="footer" class="bg-quinary py-10">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="d-flex justify-content-end">
						<span class="text-primary opacity-50">Calum Ogilvie - 2024</span>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script setup></script>