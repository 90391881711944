<template>
	<header id="header">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<nav id="navigation">
						<ul class="nav">
							<!--<li class="nav-item">-->
							<!--	<a class="nav-link text-uppercase" :href="route('logout')">Order of the day</a>-->
							<!--</li>-->
							<li class="nav-item">
								<a class="nav-link text-uppercase" :href="route('logout')">Logout</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</header>
</template>

<script setup>
window.addEventListener('scroll', function() {
	console.log('scroll check');
	let header = document.getElementById('header');

	if(isWindowScrolled()) {
		header.classList.add('scroll');
	}
	else {
		header.classList.remove('scroll');
	}
})

function isWindowScrolled() {
	return window.scrollY > 0;
}

</script>