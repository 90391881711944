import './bootstrap'; // import the bootrapping js file
import '../scss/app.scss'; // import the application styles for build

import { computed, createApp, h } from 'vue';
import { createInertiaApp, usePage } from '@inertiajs/vue3';
import { Ziggy } from "@js/ziggy.js";
import { route, ZiggyVue } from '../../vendor/tightenco/ziggy';
import LaravelPermissionToVuejs from 'laravel-permission-to-vuejs';
import aos from 'aos';

import Swal from 'sweetalert2';

window.Swal = Swal.mixin({
	iconColor: '#D9B8D9',
});
window.Toast = Swal.mixin({
	iconColor: '#D9B8D9',
	position: 'top-end',
	showConfirmButton: false,
	toast: true,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer);
		toast.addEventListener('mouseleave', Swal.resumeTimer);
	},
});

createInertiaApp({
	title: (title) => `${ title } | K & C Wedding`,
	resolve: (name) => {
		const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
		return pages[`./Pages/${ name }.vue`];
	},
	setup({ el, App, props, plugin }) {
		const myApp = createApp({ render: () => h(App, props) })
		.use(plugin)
		.use(ZiggyVue, Ziggy)
		.use(LaravelPermissionToVuejs)
		.mixin({
			methods: { route },
		});

		const permissions = computed(() => usePage().props.global.permissions)

		myApp.config.globalProperties.$permissions = permissions;
		myApp.config.globalProperties.$route = route;

		console.log(myApp.config.globalProperties);

		myApp.mount(el);

		aos.init();
	},
	progress: {
		color: '#D9B8D9',
	},
});