<template>
	<Navbar v-if="props.showNav"></Navbar>
	<section id="guest-portal">
		<slot></slot>
	</section>
	<Footer v-if="props.showNav"></Footer>
</template>

<script setup>
	import Navbar from "@js/Components/Navbar.vue";
	import Footer from "@js/Components/Footer.vue";

	const props = defineProps({
		showNav: {
			type: Boolean,
			default: true
		}
	});

	console.log(props);
</script>